import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { campaignClient, setSessionClient } from "../../../api";
import Loading from "../../../Components/Loading";
import { defaultStorageHelper } from "@spry/campaign-client";
import dayjs from "dayjs";
import BannerDropDown from "../../../Components/BannerDropDown";

declare const window: Window &
    typeof globalThis & {
        vex: any;
    };
type ReturnLogin = { sessionKey?: string; error?: string; code?: string; message?: string };

type RegisterFormData = {
    banner: string;
    storeNumber: string;

    email: string;
    region: string;
};

export default function Login() {
    const { t, i18n } = useTranslation();
    const isEnglish = i18n.language === "en";
    const navigate = useNavigate();

    const {
        register,
        setValue,
        handleSubmit,
        clearErrors,
        watch,
        formState: { errors },
    } = useForm<RegisterFormData>();

    const formwatch = watch();

    const [submitting, setSubmitting] = useState(false);

    const [showBanners, setShowBanners] = useState(false);
  

    async function handleForm(data: RegisterFormData) {
        if (submitting) {
            return;
        }
        setSubmitting(true);
  
        try {
            const { sessionKey, error, code, message } = await campaignClient.call<ReturnLogin>("login", {
                ...data,
                banner: data.banner,
                preferredLanguage: i18n.language,
            });
            if (error === "PASSWORD_UNMATCHED") {
                window.vex.dialog.alert({
                    unsafeMessage: t("login.error"),
                });
            } else if (code && message) {
                window.vex.dialog.alert({
                    unsafeMessage: message,
                });
            } else if (error && message) {
                window.vex.dialog.alert({
                    unsafeMessage: message,
                });
            } else if (sessionKey) {
                setSessionClient(campaignClient.recoverSession(sessionKey));
                defaultStorageHelper.set("expiry", dayjs().format());
                defaultStorageHelper.set("banner", data.region);

                navigate("/");
            } else {
          
            }
        } catch {
      
        }
        setSubmitting(false);
    }

    return (
        <div className="main">
            {submitting && <Loading />}
            {/* {isModal && (
                <ModalLanguage>
                    <div
                        className="popup popup--alt textCenter"
                        style={{
                            position: "relative",
                            backgroundColor: "#fff",
                            padding: "40px 70px 30px",
                        }}>
                        <div className="popup__content">
                            <h3>
                                <b>{t("login.popupTitle")}</b>
                            </h3>
                            <p>{t("login.popupBody")}</p>
                        </div>
                        <div
                            className="popup__actions"
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                                flexWrap: "wrap",
                                paddingTop: "30px",
                                gap: "8rem",
                            }}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                                <p className="imgWrapper">
                                    <picture>
                                        <img
                                            src={isEnglish ? "/assets/images/svg/sceneplus_hub_logo.svg" : "/assets/images/svg/sceneplus_hub_logo-fr.svg"}
                                            alt="card-image"
                                            width="200px"
                                        />
                                    </picture>
                                </p>
                                <br />
                                <button
                                    style={{ width: "40%" }}
                                    type="button"
                                    className="btn btn--outline"
                                    onClick={() => {
                                        setIsModal(false);
                                    }}>
                                    {t("login.enter")}
                                </button>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                                {" "}
                                <p className="imgWrapper">
                                    <picture>
                                        <img
                                            src={isEnglish ? "/assets/images/svg/paris_hub_logo.svg" : "/assets/images/svg/paris_hub_logo-fr.svg"}
                                            alt="card-image"
                                            width="170px"
                                        />
                                    </picture>
                                </p>
                                <br />
                                <a href={t("login.link")} style={{ width: "40%" }} type="button" className="btn btn--purple">
                                    {t("login.enter")}
                                </a>
                            </div>
                        </div>
                        <nav className="nav-lang">
                            <ul style={{ justifyContent: "center", paddingTop: "3rem" }}>
                                <li className={isEnglish ? "is-current" : ""}>
                                    <span className="pointer" onClick={() => changeLanguage("en")}>
                                        {t("home.30")}
                                    </span>
                                </li>

                                <li className={!isEnglish ? "is-current" : ""}>
                                    <span className="pointer" onClick={() => changeLanguage("fr")}>
                                        {" "}
                                        {t("home.31")}
                                    </span>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </ModalLanguage>
            )} */}

            <section className="section-primary white colorBlack section-form">
                <div className="section__inner">
                    <div className="shell ">
                        <div className="hero__inner">
                            <div className="hero__title center">
                                <br />
                                <h2>
                                    <b>{t("login.1")}</b>
                                </h2>
                                <p className="colorBlack">{t("login.2")}</p>
                            </div>
                            <div className="section__content">
                                <div className="form form-registration form-registration--alt" id="form-registration">
                                    <form onSubmit={handleSubmit(handleForm)}>
                                        <div className="form__body">
                                            <div className="form__row">
                                                <label htmlFor="field-last-name" className="form__label">
                                                    {t("signup.10")} *
                                                </label>
                                                <div className="form__controls">
                                                    <div className="select">
                                                        <input
                                                            className="field"
                                                            {...register("banner", {
                                                                required: {
                                                                    value: true,
                                                                    message: `${t("signup.24")}`,
                                                                },
                                                            })}
                                                            placeholder={t("signup.11")}
                                                            onChange={() => {
                                                                setValue("banner", "");
                                                            }}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setShowBanners(!showBanners);
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        />

                                                        <em />
                                                    </div>
                                                    {showBanners && <BannerDropDown setValue={setValue} setShowBanners={setShowBanners} clearErrors={clearErrors} />}
                                                </div>{" "}
                                                {errors.banner && (
                                                    <p className="error-message">
                                                        <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                                                        {errors.banner.message}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="form__row">
                                                <label htmlFor="field-email" className="form__label">
                                                    {t("signup.25")} *
                                                </label>
                                                <div className="form__controls">
                                                    <input
                                                        className="field"
                                                        placeholder={t("signup.25")}
                                                        {...register("storeNumber", {
                                                            required: {
                                                                value: true,
                                                                message: `${t("signup.26")}`,
                                                            },
                                                        })}
                                                        disabled={formwatch.storeNumber === "0000" ? true : false}
                                                    />{" "}
                                                    {errors.storeNumber && (
                                                        <p className="error-message">
                                                            <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                                                            {errors.storeNumber.message}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <small>{t("signup.34")}</small>
                                        </div>
                                        <br />
                                        <button type="submit" className="btn hero__btn" disabled={submitting} style={{ width: "100%" }}>
                                            {t("signup.35")}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* /.section-primary */}
        </div>
    );
}
